import { Button, Popconfirm, Table } from "antd";
import C from "./constant";
import "./style.css";
import { useEffect, useState } from "react";
import { listUsers } from "../../utils/api";
import { useOutletContext } from "react-router-dom";

function Live() {
  const { pl, users, high, low } = useOutletContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetch() {
      const data = await listUsers();
      setData(data);
      setLoading(false);
    }
    fetch();
  }, []);

  useEffect(() => {
    if (users?.length) {
      setData(users);
      // console.log(users);
    }
  }, [pl, users]);

  return (
    <div className="live-container">
      <div className="live-sub-container">
        <div className="pl-section">
          <h5 className="pl">
            Total P&L : &nbsp;
            <span className={`${pl < 0 ? "loss" : "profit"}`}>
              {pl < 0 ? "" : "+"}
              {pl.toLocaleString()}
            </span>{" "}
          </h5>
          <h5 className="pl">
            High : &nbsp;
            <span className={`${high < 0 ? "loss" : "profit"}`}>
              {high < 0 ? "" : "+"}
              {high.toLocaleString()}
            </span>{" "}
          </h5>
          <h5 className="pl">
            Low : &nbsp;
            <span className={`${low < 0 ? "loss" : "profit"}`}>
              {low < 0 ? "" : "+"}
              {low.toLocaleString()}
            </span>{" "}
          </h5>
        </div>
        <div className="actions">
          {C.actions.map((actions) => {
            return (
              <Popconfirm
                title={actions.title}
                description={actions.description}
                onConfirm={actions.callBack}
                okText="Yes"
              >
                <Button type="primary" danger={actions.isDanger}>
                  {actions.name}
                </Button>
              </Popconfirm>
            );
          })}
        </div>
      </div>
      <div className="table-content">
        <Table
          rowKey={"_id"}
          loading={loading}
          className="table"
          dataSource={data}
          columns={C.column}
          pagination={false}
        />
      </div>
      <div className="graph"></div>
    </div>
  );
}

export default Live;
