import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { getIntialData } from "../../utils/api";

// Register necessary components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin // Register the zoom plugin
);

const StockVarianceChart = ({ indexType, chartType = "nsignal", title }) => {
  const [candles, setCandles] = useState([]);
  const chartRef = useRef(null); // Reference to store the chart instance

  // Limit the number of candles in state (e.g., 200)
  const maxCandles = 500;

  // Function to process and add new data more efficiently
  const processNewData = useCallback((newData) => {
    setCandles((prevCandles) => {
      // Add new candle data and ensure we don't exceed the maxCandles limit
      const updatedCandles = [...prevCandles, newData].slice(-maxCandles);
      return updatedCandles;
    });
  }, []);

  useEffect(() => {
    const eventSource = new EventSource(process.env.REACT_APP_PL_SERVER);
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.graph) {
        const { label, ...rest } = data.graph;
        const newCandle = {
          label: new Date(label)
            .toLocaleTimeString()
            .split(":")
            .slice(0, -1)
            .join(":"),
          ...rest,
        };
        processNewData(newCandle); // Add new candle to the state
      }
    };

    return () => {
      eventSource.close();
    };
  }, [processNewData]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getIntialData();
      setCandles(
        data?.data?.map((ele) => {
          const { label, ...rest } = ele;
          return {
            label: new Date(label)
              .toLocaleTimeString()
              .split(":")
              .slice(0, -1)
              .join(":"),
            ...rest,
          };
        })
      );
    };
    fetch();
  }, [indexType]);

  // Move the calculation of allStocks into useMemo to avoid unnecessary recalculations
  const allStocks = useMemo(() => {
    return [
      ...new Set(
        candles
          ?.filter((c) => c?.[indexType])
          ?.flatMap((candle) => Object?.keys(candle?.[indexType]))
      ),
    ];
  }, [candles, indexType]);

  // Generate datasets for each stock with memoization for performance
  const datasets = useMemo(() => {
    return allStocks?.map((stock) => {
      const stockData = candles?.map(
        (candle) => candle?.[indexType]?.[stock]?.value || 0 // Default to 0 if stock is not in the candle
      );

      const backgroundColor = candles?.map((candle) => {
        return candle?.[indexType]?.[stock]?.[chartType] === "SELL"
          ? "red"
          : candle?.[indexType]?.[stock]?.[chartType] === "BUY"
          ? "#3ba407"
          : "blue";
      });

      const borderColor = candles?.map(() => "white");

      return {
        label: stock,
        data: stockData,
        backgroundColor,
        borderColor, // Add border color
        borderWidth: 0.5, // Set border width
      };
    });
  }, [candles, indexType, allStocks, chartType]);

  // Chart data
  const data = {
    labels: candles?.map((candle) => candle.label), // Candle labels
    datasets,
  };

  // Chart options with zoom and pan
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false, // Hide the legend
      },
      title: {
        display: true,
        text: `${title}`,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const stockName = tooltipItem.dataset.label; // Get the stock name
            const candle = candles[tooltipItem.dataIndex]; // Get the current candle
            const vwap = candle?.[indexType]?.[stockName]?.vwap || "N/A"; // Get VWAP
            const upperBand =
              candle?.[indexType]?.[stockName]?.upperBand || "N/A"; // Get VWAP
            const lowerBand =
              candle?.[indexType]?.[stockName]?.lowerBand || "N/A"; // Get VWAP

            return `${stockName} | (V: ${vwap}) | (D2: ${upperBand}) | (-D2: ${lowerBand})`;
          },
        },
      },
    },

    scales: {
      x: {
        stacked: true, // Enable stacking on the x-axis
        title: {
          display: true,
          text: "Candles",
        },
      },
      y: {
        stacked: true, // Enable stacking on the y-axis
        title: {
          display: true,
          text: "Variance",
        },
      },
    },
  };

  // Update the chart when new data is available (called when candles are updated)
  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      chartRef.current.chart.update();
    }
  }, [candles]);

  return <Bar ref={chartRef} height={90} data={data} options={options} />;
};

export default StockVarianceChart;
